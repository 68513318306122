import React from 'react';
import {graphql, StaticQuery} from 'gatsby';
import TextLoop from 'react-text-loop';

import Layout from 'components/Layout.js';
import Meta from 'components/Meta.js';
import Tile from 'components/Tile.js';

import i18n from 'data/i18n.en.json';
import obroundArrow from 'media/arrow-orange.png';
import obroundMaskArrow from 'media/obround-mask-arrow.svg';

export default class View extends React.Component {
    getIconRef(context) {
        // assumes context is a URL as this is what WordPress provides
        const segments = context.split('/');
        const slug = segments.pop() || segments.pop();
        const camelised = slug
            .split('-')
            .map((word) => word.replace(/./, (char) => char.toUpperCase()))
            .join('');
        return `icon${camelised}`;
    }

    constructor(props) {
        super(props);
        this.state = {
            isIE: false,
        };
        this.isIE = this.getIEBrowser();
    }

    getIEBrowser() {
        if (typeof navigator !== 'undefined') {
            return !!navigator.userAgent.match(/Trident\/7\./);
        }
    }

    render() {
        return (
            <StaticQuery
                query={graphql`
                    {
                        audience: allWordpressWpAudiences(
                            sort: {fields: title}
                        ) {
                            edges {
                                node {
                                    path
                                    title
                                }
                            }
                        }
                        content: wordpressPage(wordpress_id: {eq: 2}) {
                            content
                            link
                        }
                    }
                `}
                render={(data) => (
                    <Layout className="contain">
                        <Meta title="Home" />
                        <header className="manifesto">
                            <article>
                                <h1>
                                    {i18n.home.we}
                                    {!this.isIE ? (
                                        <TextLoop noWrap={false}>
                                            <>{i18n.home.createConnections}</>
                                            <>{i18n.home.enhanceExperiences}</>
                                            <>{i18n.home.powerBetterHealth}</>
                                            <>{i18n.home.areHealthShare}</>
                                        </TextLoop>
                                    ) : (
                                        <div>
                                            {i18n.home.createConnections}
                                        </div>
                                    )}
                                </h1>
                            </article>
                            <figure>
                                <img alt="" src={obroundMaskArrow} />
                                <img alt="" src={obroundArrow} />
                            </figure>
                        </header>
                        {data.content.content && (
                            <section
                                className="blocks"
                                dangerouslySetInnerHTML={{
                                    __html: data.content.content,
                                }}
                            />
                        )}
                        <nav className="tiles">
                            {data.audience.edges.map(({node}) => (
                                <Tile
                                    copy={node.tile}
                                    icon={this.getIconRef(node.path)}
                                    key={node.path}
                                    title={node.title}
                                    to={node.path}
                                />
                            ))}
                        </nav>
                    </Layout>
                )}
            />
        );
    }
}
