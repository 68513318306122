import React from 'react';
import {Link} from 'gatsby';
import PropTypes from 'prop-types';

import Graphic from 'components/Graphic.js';
import Modal from 'components/Modal.js';

export default class Tile extends React.Component {
    static propTypes = {
        copy: PropTypes.string,
        icon: PropTypes.string,
        image: PropTypes.string,
        isStatic: PropTypes.bool,
        isVideo: PropTypes.bool,
        modalContent: PropTypes.element,
        title: PropTypes.string.isRequired,
        to: PropTypes.string,
    };

    constructor(props) {
        super(props);
        this.state = {
            modalOpen: false,
        };
        this.toggleModal = this.toggleModal.bind(this);
    }

    renderContent() {
        return (
            <>
                {this.props.image && (
                    <figure
                        style={{
                            backgroundImage: `url(${this.props.image})`,
                        }}
                    >
                        {this.props.isVideo && <Graphic name="iconPlay" />}
                    </figure>
                )}
                {this.props.icon && <Graphic name={this.props.icon} />}
                <h4>{this.props.title}</h4>
                {this.props.copy && <p>{this.props.copy}</p>}
            </>
        );
    }

    toggleModal() {
        this.setState((state) => ({...state, modalOpen: !state.modalOpen}));
    }

    render() {
        let classList = 'tile';
        if (this.props.isVideo) {
            classList += ' video';
        }
        return (
            <>
                {this.props.modalContent && (
                    <>
                        <button className="tile" onClick={this.toggleModal}>
                            {this.renderContent()}
                        </button>
                        <Modal
                            closeModal={this.toggleModal}
                            isOpen={this.state.modalOpen}
                        >
                            {this.props.modalContent}
                        </Modal>
                    </>
                )}
                {this.props.isStatic && (
                    <span className={classList}>{this.renderContent()}</span>
                )}
                {this.props.to && (
                    <Link className={classList} to={this.props.to}>
                        {this.renderContent()}
                    </Link>
                )}
            </>
        );
    }
}
