import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';

import Graphic from 'components/Graphic.js';

export default class Modal extends React.Component {
    static propTypes = {
        closeModal: PropTypes.func.isRequired,
        isOpen: PropTypes.bool.isRequired,
    };

    constructor(props) {
        super(props);
        this.handleDocumentPress = this.handleDocumentPress.bind(this);
        this.handleKeyDown = this.handleKeyDown.bind(this);
        this.setWrapperRef = this.setWrapperRef.bind(this);
    }

    componentDidMount() {
        this.modalEl = document.getElementById('modal');
    }

    componentDidUpdate(prevProps) {
        if (this.props.isOpen && this.props.isOpen !== prevProps.isOpen) {
            document.addEventListener('click', this.handleDocumentPress);
            document.addEventListener('keydown', this.handleKeyDown);
            document.body.classList.add('no-scroll');
        }
        if (!this.props.isOpen && prevProps.isOpen) {
            document.removeEventListener('click', this.handleDocumentPress);
            document.removeEventListener('keydown', this.handleKeyDown);
            document.body.classList.remove('no-scroll');
        }
    }

    handleDocumentPress(event) {
        if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
            event.stopPropagation();
            this.props.closeModal();
        }
    }

    handleKeyDown(event) {
        if (event.key === 'Escape' || event.key === 'Esc') {
            this.props.closeModal();
        }
    }

    setWrapperRef(node) {
        this.wrapperRef = node;
    }

    renderModalContent() {
        return (
            <div className="modal-content" ref={this.setWrapperRef}>
                <button onClick={this.props.closeModal}>
                    <Graphic name="iconClose" />
                </button>
                {this.props.children}
            </div>
        );
    }

    render() {
        if (this.modalEl) {
            this.modalEl.setAttribute('aria-expanded', this.props.isOpen);
        }
        if (this.props.isOpen) {
            return ReactDOM.createPortal(
                this.renderModalContent(),
                this.modalEl,
            );
        }
        return null;
    }
}
